import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Loading from "../images/loading.svg"; // Ensure you have the loading.svg in the correct path

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FakeLogin = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const username = query.get("username");
    const password = query.get("password");

    if (username && password) {
      localStorage.clear();
      login(username, password);
    }
  }, [searchParams]);

  const login = async (username, password) => {
    setError(""); // Reset error message
    console.log(username);
    console.log(password);

    for (let attempt = 1; attempt <= 10; attempt++) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before each attempt
        const response = await axios.post("/api/login", { username, password });
        const { token } = response.data;
        localStorage.setItem("token", token); // Store token in local storage

        const redirectPage = query.get("page") || "/browse-candidates"; // Get the page parameter or default to /browse-candidates
        const roleId = query.get("roleId");
        const redirectUrl = roleId
          ? `${redirectPage}?roleId=${roleId}`
          : redirectPage;

        navigate(redirectUrl); // Redirect to the specified page
        window.location.reload();
        return; // Exit the function if login is successful
      } catch (err) {
        if (attempt === 10) {
          setError("Invalid username or password");
        } else {
          console.log(`Attempt ${attempt} failed, retrying...`);
        }
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 flex flex-col items-center justify-center md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Signing you into your account
          </h1>
          <img src={Loading} className="h-6" alt="Loading" />
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default FakeLogin;
