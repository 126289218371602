import linkedin from "../../images/linkedin-logo.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import Office from "../../images/office-icon.png"; // Ensure you have the loading.svg in the correct path
import Languages from "../../images/languages-icon.png"; // Ensure you have the loading.svg in the correct path
import "flowbite";

function CandidateCard({
  candidate,
  setInterviewNeedUpdate,
  setShowPopup,
  setShowFinalPopup,
  candidatesRequest,
  setCandidatesRequest,
}) {
  const formattedWork = candidate.work
    ? candidate.work
        .split(/(\d\)\s)/)
        .filter((part) => part.trim())
        .map((part, index) => {
          if (index % 2 === 1) {
            const [position, rest] = part.split("@");
            const [company, details] = rest ? rest.split(/-\s/, 2) : ["", ""];
            return (
              <div key={index} className="font-normal text-sm text-gray-500 ">
                <p className="text-black">
                  <span className="font-semibold">{position.trim()}</span> @{" "}
                  {company.trim()}
                </p>
                {details ? details.trim() : ""}
              </div>
            );
          }
          return (
            <p key={index} className="mb-2 font-normal text-sm text-gray-400">
              {part.replace(/\d\)\s/, "")}
            </p>
          );
        })
    : null;

  // Define the color classes based on the stage
  const getColorClasses = (stage) => {
    switch (stage) {
      case "Match requested":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
        };
      case "Application pending":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
        };
      case "Being interviewed":
        return {
          bgColor: "bg-green-100",
          textColor: "text-green-700",
          workBgColor: "bg-green-50",
          fillColor: "fill-green-700",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
        };
    }
  };

  const { bgColor, textColor, workBgColor, fillColor } = getColorClasses(
    candidate.stage
  );

  const [active, setActive] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (stage) => {
    const updatedData = {
      fields: {
        Stage: stage,
      },
      interviewId: candidate.interviewId,
    };
    setError(""); // Reset error message
    setLoading(true); // Set loading state to true
    try {
      const response = await axios.patch(
        `/api/interviews/updateInterviewRequest`,
        updatedData
      );

      console.log(response);

      setInterviewNeedUpdate(true);
      if (stage === "Interviewing (application)") {
        setShowPopup(true); // Show popup when a positive offer is sent
      }

      // Reduce candidatesRequest by one
      setCandidatesRequest((prev) => {
        const updatedCount = prev - 1;
        if (updatedCount === 0) {
          setShowFinalPopup(true);
        }
        return updatedCount;
      });
    } catch (err) {
      setError(err.message); // Set error message
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const previewText =
    candidate.rationale?.split(" ").slice(0, 10).join(" ") + "...";

  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/512/4120/4120345.png";

  const [ranking, setRanking] = useState(candidate.ranking);

  useEffect(() => {
    setRanking(candidate.ranking);
  }, [candidate.ranking]);

  const handleSvgClick = async (svg) => {
    let newRanking;
    if (ranking === "Not ranked") {
      newRanking = svg === "first" ? "Thumbs down" : "Thumbs up";
    } else if (ranking === "Thumbs up" && svg === "first") {
      newRanking = "Thumbs down";
    } else if (ranking === "Thumbs down" && svg === "second") {
      newRanking = "Thumbs up";
    } else {
      newRanking = "Not ranked";
    }

    setRanking(newRanking);

    const updatedData = {
      fields: {
        Ranking: newRanking,
      },
      interviewId: candidate.interviewId,
    };
    try {
      await axios.patch("/api/interviews/updateInterviewRequest", updatedData);
      setInterviewNeedUpdate(true);
    } catch (err) {}
  };

  return (
    <div className="relative max-w-xl mx-auto mt-5 pt-4 pr-4 pl-4 bg-white rounded-lg  overflow-hidden flex flex-col h-full hover:drop-shadow-lg transition duration-400">
      <a>
        <div className="relative">
          <img
            className="w-full h-72 object-cover rounded-lg"
            src={candidate.image || fallbackImageUrl}
            alt="Candidate"
            onError={(e) => {
              e.target.src = fallbackImageUrl;
            }}
          />
          <div className="bg-white rounded-md absolute top-2 left-2 flex p-2 space-x-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className={`size-6 cursor-pointer ${
                ranking === "Thumbs down" ? "text-red-500" : ""
              }`}
              onClick={() => handleSvgClick("first")}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className={`size-6 cursor-pointer ${
                ranking === "Thumbs up" ? "text-green-500" : ""
              }`}
              onClick={() => handleSvgClick("second")}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
              />
            </svg>
          </div>

          <span
            className={`absolute top-2 right-2 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
          >
            {candidate.stage === "Application pending" ? (
              <>
                Applied to <strong>{candidate.role_title}</strong>
              </>
            ) : (
              candidate.stage
            )}
          </span>
        </div>
      </a>
      <div className="pt-2 flex flex-col flex-grow">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h5 className="text-xl font-semibold tracking-tight text-gray-900">
              {candidate.name}
            </h5>
            <img
              src={linkedin}
              alt={candidate.name}
              title="Linkedin icon"
              className="h-5 w-5 ml-2 rounded-sm"
              onClick={(e) => {
                e.preventDefault();
                let url = candidate.linkedin;
                if (!url.startsWith("https://")) {
                  url = "https://" + url;
                }
                window.open(url, "_blank");
              }}
              style={{ cursor: "pointer" }}
            />

            {candidate.cv && (
              <img
                src={cv}
                alt={candidate.cv}
                title="CV icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.cv, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            {candidate.github && (
              <img
                src={github}
                alt={candidate.github}
                title="Github icon"
                className="h-5 w-5 ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(candidate.github, "_blank");
                }}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
          <div className="flex flex-col text-right">
            <h5 className="text-sm tracking-tight text-gray-900">
              Expected Salary Range: {candidate.expectedSalaryRange}
            </h5>
            <h5 className="text-sm tracking-tight text-gray-900 ">
              {candidate.salary}
            </h5>
          </div>
        </div>

        <div className="mt-2">
          {candidate.roles &&
            typeof candidate.roles[0] === "string" &&
            candidate.roles[0].split(",").map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {role.trim()}
              </span>
            ))}
          {candidate.sales && (
            <span
              className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.sales}
            </span>
          )}
          {candidate.swe_roles &&
            candidate.swe_roles.map((role, index) => (
              <span
                key={index}
                className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
              >
                {role.trim()}
              </span>
            ))}
          {candidate.time_coding && (
            <span
              className={`inline-flex mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
            >
              {candidate.time_coding} of coding exp
            </span>
          )}
        </div>

        <div className="flex pb-2 pt-1">
          {candidate.working_style && (
            <p className=" pr-4 font-normal flex text-sm text-black ">
              <img className="h-6 pr-1 pb-1" src={Office} />
              {candidate.working_style}
            </p>
          )}
          {candidate.languages !== "N/A" && candidate.languages && (
            <p className="font-normal flex text-sm text-black ">
              <img className="h-5  pr-1" src={Languages} />
              {candidate.languages}
            </p>
          )}
        </div>

        <p className="text-sm">
          {candidate.education &&
            candidate.education.length > 0 &&
            (() => {
              const educationText = candidate.education;
              if (educationText.includes("; ")) {
                const splitText = educationText.split("; ")[1];
                const [beforeAt, afterAt] = splitText.split(" @ ");
                const [firstPart, ...restParts] = beforeAt.split("&");
                return (
                  <>
                    <span className="font-semibold">{firstPart.trim()}</span>
                    {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                    {afterAt ? ` @ ${afterAt}` : ""}
                  </>
                );
              }
              const [beforeAt, afterAt] = educationText.split(" @ ");
              const [firstPart, ...restParts] = beforeAt.split("&");
              return (
                <>
                  <span className="font-semibold">{firstPart.trim()}</span>
                  {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                  {afterAt ? ` @ ${afterAt}` : ""}
                </>
              );
            })()}
        </p>

        <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
          {candidate.recentTarget && (
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Recent Target Achieved:
              </span>{" "}
              {candidate.recentTarget}
            </div>
          )}
          {formattedWork}
        </div>

        {candidate.description && (
          <p className="mt-2 font-normal text-sm text-gray-700">
            <span className="font-semibold text-black">Most proud of: </span>
            {candidate.description}
          </p>
        )}

        {candidate.video && (
          <a
            onClick={(e) => {
              e.preventDefault();
              window.open(candidate.video, "_blank");
            }}
            className="mt-2 font-normal text-sm text-blue-600 "
            style={{ cursor: "pointer" }}
          >
            <span className="font-semibold">Video intro - get to know me!</span>
          </a>
        )}

        {candidate.rationale && (
          <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
            <div className="font-normal text-sm text-gray-700 pt-2">
              <span className="font-semibold text-black">
                Application Rationale:
              </span>{" "}
              {isExpanded ? candidate.rationale : previewText}
              <button
                onClick={handleToggle}
                className="text-blue-500 ml-2 underline"
              >
                {isExpanded ? "Show Less" : "Read More"}
              </button>
            </div>
          </div>
        )}

        {candidate.stage === "Application pending" && (
          <div className="flex mt-auto mb-4">
            {loading ? (
              <div className="flex justify-center items-center w-full h-12 bg-gray-100 rounded-lg">
                <img src={Loading} className="h-6" alt="Loading" />
              </div>
            ) : (
              <>
                <div className="flex mt-auto mb-4">
                  <button
                    onClick={() => handleSubmit("Interviewing")}
                    className=" group px-8 py-2 text-sm mr-1 text-black  rounded-lg font-medium transform hover:bg-white bg-[#02B491] hover:text-black text-white transition duration-400 border-2 border-[#02B491]"
                    title="We'll introduce you via email."
                  >
                    Accept Interview Request
                    <p className="flex mt-1 text-white font-normal text-xs group-hover:text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-4 -ml-4 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                        />
                      </svg>
                      You'll be introduced over email!
                    </p>
                  </button>
                  <button
                    data-tooltip-target="tooltip-default"
                    type="button"
                    onClick={() => handleSubmit("Rejected @ CV")}
                    className="px-8 group py-2 text-sm ml-1  rounded-lg font-medium transform bg-white hover:bg-gray-500 text-black hover:text-white transition duration-400 border-2 border-gray-500"
                  >
                    Decline Interview Request
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CandidateCard;
