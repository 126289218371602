import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SmallCandidateCard from "../../components/candidates/small_candidate";
import FullPage from "../../images/open-full-page.png";

import { Navigation } from "swiper/modules";
import Swiper from "swiper";
import "./swiper.min.css";
Swiper.use([Navigation]);

function Shortlists() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const swiperInstances = useRef([]);
  const navigate = useNavigate();

  const handleShortlist = (roleURLId) => {
    navigate(`/shortlist?roleRequestId=${roleURLId}`);
    window.location.reload();
  };

  useEffect(() => {
    const initializeCarousels = () => {
      swiperInstances.current.forEach((swiper) => swiper.destroy(true, true));
      swiperInstances.current = [];

      const carousels = document.querySelectorAll(".carousel");
      carousels.forEach((carousel, index) => {
        swiperInstances.current.push(
          new Swiper(carousel, {
            slidesPerView: "auto",
            grabCursor: true,
            loop: false,
            centeredSlides: false,
            initialSlide: 0,
            spaceBetween: 24,
            watchSlidesProgress: true,
            navigation: {
              nextEl: `.carousel-next-${index}`,
              prevEl: `.carousel-prev-${index}`,
            },
          })
        );
      });
    };

    if (!loading) {
      initializeCarousels();
    }

    return () => {
      swiperInstances.current.forEach((swiper) => swiper.destroy(true, true));
    };
  }, [loading, data]);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;

        let shortlistedCandidatesByRole = {};

        roles.forEach((role) => {
          if (role["Shortlisted Candidates"]) {
            const roleTitle = role["Role Title"];
            const roleURLId = role["URL ID"];
            const roleId = role["id"];

            if (!shortlistedCandidatesByRole[roleTitle]) {
              shortlistedCandidatesByRole[roleTitle] = {
                roleURLId: roleURLId,
                roleId: roleId,
                candidates: [],
              };
            }
            shortlistedCandidatesByRole[roleTitle].candidates =
              shortlistedCandidatesByRole[roleTitle].candidates.concat(
                role["Shortlisted Candidates"]
              );
          }
        });

        const roleNames = Object.keys(shortlistedCandidatesByRole);
        if (roleNames.length > 0) {
          for (const roleName of roleNames) {
            await fetchCandidates(
              shortlistedCandidatesByRole[roleName].candidates,
              roleName,
              shortlistedCandidatesByRole[roleName].roleURLId,
              shortlistedCandidatesByRole[roleName].roleId
            );
          }
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchCandidates = async (
      candidateIds,
      roleName,
      roleURLId,
      roleId
    ) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/candidates/fetchCandidates", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            ids: candidateIds.join(","),
          },
        });

        const candidates = response.data.candidates;

        const transformedData = candidates.map((candidate) => {
          const mostRecentCohort =
            candidate.fields?.["Most recent cohort"] || "";
          let stream = "";

          if (/^JS\d+$/.test(mostRecentCohort)) {
            stream = "Generalist";
          } else if (/^JSS\d+$/.test(mostRecentCohort)) {
            stream = "Sales";
          } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
            stream = "Software Engineering";
          } else if (/^JSM\d+$/.test(mostRecentCohort)) {
            stream = "Marketing";
          }

          return {
            id: candidate.id || "",
            url_id: candidate.fields?.["ID"] || "",
            name: candidate.fields?.["Name"] || "Unknown",
            education: Array.isArray(candidate.fields?.["Degree details"])
              ? candidate.fields["Degree details"]
              : [
                  candidate.fields?.["Degree details"] ||
                    "No details available",
                ],
            image: candidate.fields?.Photo
              ? candidate.fields.Photo[0]?.url || null
              : null,
            stream: stream,
            role: roleName,
            roleURLId: roleURLId,
            roleId: roleId,
          };
        });

        setData((prevData) => ({
          ...prevData,
          [roleName]: (prevData[roleName] || []).concat(transformedData),
        }));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchRole();
  }, []);

  const handleRequestInterview = (roleURLId) => {
    navigate(`/request-interview?roleRequestId=${roleURLId}`);
  };

  const handleRemoveCandidate = async (role, candidateId) => {
    if (!data[role]) {
      console.error(`Role ${role} not found in data`);
      return;
    }

    const currentShortlistedCandidates = data[role] || [];
    const updatedShortlistedCandidates = currentShortlistedCandidates
      .filter((candidate) => candidate.id !== candidateId)
      .map((candidate) => candidate.id);

    console.log(currentShortlistedCandidates);
    console.log(updatedShortlistedCandidates);

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: data[role][0].roleId,
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setData((prevData) => ({
        ...prevData,
        [role]: prevData[role].filter(
          (candidate) => candidate.id !== candidateId
        ),
      }));
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (Object.keys(data).length === 0) {
    return (
      <div className="pl-6 pt-28 bg-white flex flex-col items-center">
        <div className="max-w-6xl w-full flex flex-col h-full bg-white">
          <h2 className="block pb-6 text-4xl font-normal leading-6 text-gray-900">
            Shortlists
          </h2>
          <p>You have not shortlisted any candidates yet.</p>
          <button
            type="button"
            onClick={() => navigate("/candidates")}
            className="mt-4 px-4 py-2 max-w-[200px] bg-[#02B491] hover:bg-white border-2 border-[#02B491] hover:text-black text-white rounded"
          >
            Browse Candidates
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center bg-white pt-28 pl-4 pr-4 overflow-x-hidden">
      <div className="max-w-6xl w-full flex flex-col h-full bg-white">
        <h2 className="block pb-6 text-4xl font-normal leading-6 text-gray-900">
          Shortlists
        </h2>
        {Object.keys(data).map((role, index) => (
          <div key={role} className="rounded-md relative  p-4 mb-6">
            <h2 className="block text-2xl pl-4 pt-4 font-normal leading-6 text-gray-900">
              {role}
            </h2>
            <button
              onClick={() => handleRequestInterview(data[role][0].roleURLId)}
              className="px-8 py-2 absolute top-4 right-20 rounded-md bg-[#02B491] text-white font-bold transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
            >
              Request Interview →
            </button>
            <img
              onClick={() => handleShortlist(data[role][0].roleURLId)}
              src={FullPage}
              className="h-8 absolute top-6 right-8 cur"
            />
            <div className="">
              <div className="carousel swiper-container max-w-full mx-auto sm:max-w-none">
                <div className="swiper-wrapper">
                  {data[role].map((candidate, candidateIndex) => (
                    <div
                      key={candidateIndex}
                      className="swiper-slide max-w-[375px] h-auto"
                    >
                      <SmallCandidateCard
                        candidate={candidate}
                        handleRemoveCandidate={handleRemoveCandidate}
                        role={role}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex mt-12 space-x-3 justify-end pr-4">
                <button
                  className={`carousel-prev carousel-prev-${index} relative z-20 w-11 h-11 rounded-full flex items-center justify-center group bg-gray-900`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="fill-white group-hover:fill-[#02B491] transition duration-150 ease-in-out"
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m3.914 5 3.5-3.5L6 .086 1.086 5H1v.086L.086 6 1 6.914V7h.086L6 11.914 7.414 10.5 3.914 7H13V5z" />
                  </svg>
                </button>
                <button
                  className={`carousel-next carousel-next-${index} relative z-20 w-11 h-11 rounded-full flex items-center justify-center group bg-gray-900`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="fill-white group-hover:fill-[#02B491] transition duration-150 ease-in-out"
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m9.086 5-3.5-3.5L7 .086 11.914 5H12v.086l.914.914-.914.914V7h-.086L7 11.914 5.586 10.5l3.5-3.5H0V5z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Shortlists;
