import React, { useEffect, useState, useCallback, useRef } from "react";
import linkedin from "../../images/linkedin-logo.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import Loading from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path
import { useNavigate } from "react-router-dom";
import Office from "../../images/office-icon.png"; // Ensure you have the loading.svg in the correct path
import Languages from "../../images/languages-icon.png"; // Ensure you have the loading.svg in the correct path
import save from "../../images/save-icon.png";
import { useShortlisted } from "../../provider/ShortlistedContext";
import LoadingWhite from "../../images/loading-white.svg"; // Ensure you have the loading.svg in the correct path
import LoadingBlack from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path

function Candidate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [candidate, setCandidate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [candidateRoles, setCandidateRoles] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [roles, setRoles] = useState([]);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(null);
  const { shortlistedCount, setShortlistedCount } = useShortlisted();
  const [showRoleMenu, setShowRoleMenu] = useState(false);
  const roleMenuRef = useRef(null);
  const [newRoleTitle, setNewRoleTitle] = useState("");
  const [newRoleForm, setNewRoleForm] = useState(false);

  const handleAddToShortlist = () => {
    setShowRoleMenu(!showRoleMenu);
  };
  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };
  const newRole = () => {
    setNewRoleForm(true);
  };
  const fetchInterviews = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      const response = await axios.get("/api/interviews/fetchInterviews", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const interviews = response.data.interviews || []; // Ensure it defaults to an empty array if undefined

      const transformedCandidate = interviews.map((interview) => {
        let stage;
        if (
          interview["Stage"] === "Interviewing (request accepted)" ||
          interview["Stage"] === "Interviewing (application)"
        ) {
          stage = "Being interviewed";
        } else {
          stage = interview["Stage"];
        }
        return {
          role_id: interview["id"] || undefined,
          role_title: interview["Role Title (from Role)"]?.[0] || "", // Use optional chaining and default empty string
          candidate: interview["Candidate"]?.[0] || "", // Use optional chaining and default empty string
          stage: stage,
          rationale: interview["Application Rationale"] || undefined,
        };
      });

      setCandidateRoles(transformedCandidate);
    } catch (err) {}
  };

  const fetchShortlistedCandidates = useCallback(async () => {
    if (!rolesNeedUpdate) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/roles/fetchRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const roles = response.data.roles || []; // Ensure it defaults to an empty array if undefined

      let shortlistedCandidatesByRole = {};
      let rolesList = [];

      roles.forEach((role) => {
        const roleTitle = role["Role Title"];
        const roleUrlId = role["URL ID"];
        const roleId = role["id"];
        rolesList.push(roleTitle);
        if (role["Shortlisted Candidates"]) {
          if (!shortlistedCandidatesByRole[roleTitle]) {
            shortlistedCandidatesByRole[roleTitle] = {
              roleUrlId: roleUrlId,
              roleId: roleId,
              candidates: [],
            };
          }
          shortlistedCandidatesByRole[roleTitle].candidates =
            shortlistedCandidatesByRole[roleTitle].candidates.concat(
              role["Shortlisted Candidates"]
            );
        }
      });

      setShortlistedCandidates(shortlistedCandidatesByRole);
      setLoading(false);
      setButtonLoading(false);
      setRoles(roles);
      setRolesNeedUpdate(false);
    } catch (err) {}
  }, [rolesNeedUpdate]);

  useEffect(() => {
    fetchInterviews();
    fetchShortlistedCandidates();

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/candidates/fetchCandidate?id=${id}`
        );
        console.log("ok");

        const candidate = response.data.candidate;

        const mostRecentCohort = candidate.fields["Most recent cohort"];
        let stream = "";

        if (/^JS\d+$/.test(mostRecentCohort)) {
          stream = "Generalist";
        } else if (/^JSS\d+$/.test(mostRecentCohort)) {
          stream = "Sales";
        } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
          stream = "Software Engineering";
        } else if (/^JSM\d+$/.test(mostRecentCohort)) {
          stream = "Marketing";
        }

        let sweRoles = candidate.fields["SWE relevant roles"];
        if (sweRoles) {
          sweRoles = Array.isArray(sweRoles)
            ? sweRoles[0].split(",").map((role) => role.trim())
            : sweRoles.split(",").map((role) => role.trim());
          sweRoles = transformRoles(sweRoles);
        } else {
          sweRoles = []; // Ensure it's an empty array if not present
        }

        // Determine the correct salary field
        let salary;
        if (stream === "Generalist" || stream === "Marketing") {
          salary = candidate.fields["Salary - cleaned"];
        } else if (stream === "Sales") {
          salary = candidate.fields["Base range + commission"];
        } else if (stream === "Software Engineering") {
          salary = candidate.fields["SWE Salary - cleaned"];
        }

        const transformedData = {
          id: candidate["id"] || "",
          url_id: candidate.fields["ID"] || "",
          createdTime: candidate["createdTime"], // Ensure this is correctly fetched
          years_of_exp:
            candidate.fields["Years of exp (or commercial exp)"] || "",
          cv: candidate.fields["Raw CV"] || "",
          video: candidate.fields["Raw video intro"] || "",
          linkedin: candidate.fields["LinkedIn raw URL"] || "",
          name: candidate.fields["Name"] || "",
          lastName: candidate.fields["Surname"] || "",
          education: candidate.fields["Degree details"] || undefined,
          roles: candidate.fields["Generalist roles suitable for"] || "",
          salary: salary, // Use the determined salary
          email: candidate.fields["Email"] || "",
          image: candidate.fields.Photo ? candidate.fields.Photo[0].url : null,
          work: candidate.fields["Raw work experience"] || "",
          working_style:
            candidate.fields["Preferred working style G/M/S"] || "",
          github: candidate.fields["Github"] || "",
          swe_roles: sweRoles,
          languages: candidate.fields["Languages"] || "",
          time_coding: candidate.fields["Time spent coding"] || "",
          description: candidate.fields["Top achievement"] || "",
          mostRecentCohort: candidate.fields["Most recent cohort"] || "",
          recentTarget: candidate.fields["Recent target achieved"] || "",
          stream: stream, // Add the stream field
          sales:
            candidate.fields[
              "Proven Sales Experience / High Potential for Sales"
            ],
        };

        setCandidate(transformedData);
        setLoading(false);
        console.log(transformedData);
      } catch (err) {
        setError(err.message);
        setButtonLoading(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, rolesNeedUpdate]);

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!candidate) {
    return <p>No candidate data found</p>;
  }

  const formattedWork = candidate.work
    ? candidate.work
        .split(/(\d\)\s)/)
        .filter((part) => part.trim())
        .map((part, index) => {
          if (index % 2 === 1) {
            const [position, rest] = part.split("@");
            const [company, details] = rest ? rest.split(/-\s/, 2) : ["", ""];
            return (
              <div key={index} className="font-normal text-md text-gray-500">
                <p className="text-black">
                  <span className="font-semibold">{position.trim()}</span> @{" "}
                  {company.trim()}
                </p>
                {details ? details.trim() : ""}
              </div>
            );
          }
          return (
            <p key={index} className="mb-2 font-normal text-md text-gray-400 ">
              {part.replace(/\d\)\s/, "")}
            </p>
          );
        })
    : null;

  const getColorClasses = (stream) => {
    switch (stream) {
      case "Generalist":
        return {
          bgColor: "bg-emerald-100",
          textColor: "text-emerald-700",
          workBgColor: "bg-emerald-50",
          fillColor: "fill-emerald-700",
          bgColor2: "bg-emerald-400",
          borderColor: "border-emerald-400",
        };
      case "Sales":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
          bgColor2: "bg-blue-400",
          borderColor: "border-blue-400",
        };
      case "Software Engineering":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
          bgColor2: "bg-orange-400",
          borderColor: "border-orange-400",
        };
      case "Marketing":
        return {
          bgColor: "bg-purple-100",
          textColor: "text-purple-700",
          workBgColor: "bg-purple-50",
          fillColor: "fill-purple-700",
          bgColor2: "bg-purple-400",
          borderColor: "border-purple-400",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
          bgColor2: "bg-gray-400",
          borderColor: "border-gray-400",
        };
    }
  };

  const handleNewRoleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      fields: {
        "Role Title": newRoleTitle,
      },
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post("/api/roles/createRole", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data.newRoleData);

      handleRoleSelect(response.data.newRoleData, candidate.id);
      setRolesNeedUpdate(true);
      setNewRoleForm(false);
      setNewRoleTitle("");
    } catch (err) {
      console.error(err);
    }
  };

  const handleRoleSelect = async (role, candidateId) => {
    setButtonLoading(true);
    const currentShortlistedCandidates = role["Shortlisted Candidates"] || [];
    const updatedShortlistedCandidates = [
      ...new Set([...currentShortlistedCandidates, candidateId]),
    ];

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: role.id,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const updatedRoleData = response.data.updatedRoleData.fields;

      setRoles((prevRoles) => {
        const updatedRoles = prevRoles.map((r) =>
          r.id === updatedRoleData.id ? updatedRoleData : r
        );
        return updatedRoles;
      });

      const newUpdatedRoles = roles.map((r) =>
        r.id === updatedRoleData.id ? updatedRoleData : r
      );

      // Recalculate the shortlisted count
      const newShortlistedCount = newUpdatedRoles.reduce(
        (acc, role) =>
          acc +
          (role["Shortlisted Candidates"]
            ? role["Shortlisted Candidates"].length
            : 0),
        0
      );
      setRolesNeedUpdate(true);
      fetchShortlistedCandidates();
      setShortlistedCount(newShortlistedCount);
    } catch (err) {
      console.error(err);
    } finally {
      setShowRoleMenu(false);
    }
  };

  const handleRemoveCandidate = async (roles, role, candidateId) => {
    setButtonLoading(true);
    const currentShortlistedCandidates = roles[role]["candidates"] || [];
    const updatedShortlistedCandidates = currentShortlistedCandidates.filter(
      (candidate) => candidate !== candidateId
    );

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: roles[role].roleId,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        "/api/roles/updateRoleData",
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const updatedRoleData = response.data.updatedRoleData.fields;

      setRoles((prevRoles) => ({
        ...prevRoles,
        [role]: {
          ...prevRoles[role],
          candidates: updatedShortlistedCandidates,
        },
      }));

      const newShortlistedCount = shortlistedCount - 1;

      setShortlistedCount(newShortlistedCount);

      setRolesNeedUpdate(true);
      fetchShortlistedCandidates();
    } catch (err) {
      console.error(err);
    } finally {
      setShowRoleMenu(false);
    }
  };

  const handleRemoveCandidateFromRole = async (role, candidateId) => {
    console.log(role);
    const currentShortlistedCandidates = role.shortlisted_candidates || [];
    const updatedShortlistedCandidates = currentShortlistedCandidates.filter(
      (candidate) => candidate !== candidateId
    );

    console.log(currentShortlistedCandidates);
    console.log(updatedShortlistedCandidates);

    const updatedData = {
      fields: {
        "Shortlisted Candidates": updatedShortlistedCandidates,
      },
      roleId: role.id,
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/roles/updateRoleData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setRolesNeedUpdate(true);
    } catch (err) {
      console.error(err);
    }
  };

  const isShortlistedForRole = (candidateId, roles) => {
    for (const role in roles) {
      if (roles[role].candidates.includes(candidateId)) {
        return role;
      }
    }
    return null;
  };

  const roleShortlistedFor = isShortlistedForRole(
    candidate.id,
    shortlistedCandidates
  );

  const { bgColor, textColor, workBgColor, fillColor, bgColor2, borderColor } =
    getColorClasses(candidate.stream);

  const profileFallback =
    "https://via.placeholder.com/600x400.png?text=No+Image+Available";

  const appliedRole = candidateRoles.find(
    (role) => role.candidate === candidate.id
  );

  const roleAppliedTag =
    appliedRole && appliedRole.stage === "Application pending" ? (
      <span
        className={`absolute top-2 right-2 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
      >
        <>
          Applied to <strong>{appliedRole.role_title}</strong>
        </>
      </span>
    ) : null;

  const handleSubmit = async (stage) => {
    const updatedData = {
      fields: {
        Stage: stage,
      },
      interviewId: appliedRole.role_id,
    };
    setError(""); // Reset error message
    setSubmitting(true); // Set loading state to true
    try {
      const response = await axios.patch(
        `/api/interviews/updateInterviewRequest`,
        updatedData
      );

      console.log(response);
      navigate("/applications");
    } catch (err) {
      setError(err.message); // Set error message
    } finally {
      setSubmitting(false); // Set loading state to false
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center bg-white pb-20">
        <div className="max-w-[700px] flex flex-col h-full mt-20 bg-white animate-pulse">
          <div className="h-[400px] w-[700px] bg-gray-300 rounded-xl"></div>
          <div className="h-10 w-48 bg-gray-300 rounded mt-2"></div>
          <div className="flex h-6 mt-2">
            <div className="h-6 w-20 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-24 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-24 mr-2 bg-gray-300 rounded"></div>
          </div>
          <div className="flex h-6 mt-2">
            <div className="h-6 w-60 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-60 mr-2 bg-gray-300 rounded"></div>
          </div>
          <div className="h-6 w-full bg-gray-300 rounded mt-2"></div>

          <div className="h-6 w-80 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-full bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-80 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-full bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-full bg-gray-300 rounded mt-2"></div>
        </div>
      </div>
    );
  }

  const roleAppliedButtons =
    appliedRole && appliedRole.stage === "Application pending" ? (
      <div className="flex mt-auto mb-4">
        {submitting ? (
          <div className="flex mt-4 justify-center items-center w-full h-12 bg-gray-100 rounded-lg">
            <img src={Loading} className="h-6" alt="Loading" />
          </div>
        ) : (
          <>
            <button
              onClick={() => handleSubmit("Interviewing")}
              className="px-2 py-2 mt-4 text-md mr-1 text-black flex rounded-lg font-medium transform hover:bg-white bg-[#02B491] hover:text-black text-white transition duration-400 border-2 border-[#02B491]"
            >
              Accept Interview Request
            </button>
            <button
              onClick={() => handleSubmit("Rejected @ CV")}
              className="px-2 py-2 mt-4 text-md ml-1  text-black flex rounded-lg font-medium transform hover:bg-white bg-gray-500 hover:text-black text-white transition duration-400 border-2 border-gray-500"
            >
              Decline Interview Request
            </button>
          </>
        )}
      </div>
    ) : null;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const previewText =
    candidate.rationale?.split(" ").slice(0, 10).join(" ") + "...";

  const roleAppliedRational =
    appliedRole && appliedRole.rationale ? (
      <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
        <div className="font-normal text-md text-gray-700 pt-2">
          <span className="font-semibold text-black">
            Application Rationale:
          </span>{" "}
          {isExpanded ? appliedRole.rationale : previewText}
          <button
            onClick={handleToggle}
            className="text-blue-500 ml-2 underline"
          >
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        </div>
      </div>
    ) : null;

  return (
    <div className="pt-20 pl-64 pr-2 bg-white min-h-screen h-full flex item-start">
      <div>
        <button
          type="button"
          onClick={() => navigate("/browse-candidates")}
          className="mx-4 px-4 py-2  text-gray-800 rounded-md"
        >
          ← Back to all candidates
        </button>
      </div>
      <div>
        <div className="flex relative flex-col items-center bg-white rounded-xl p-4 mb-10 pb-10">
          <div className="max-w-[700px] flex flex-col h-full bg-white gap-2">
            <div className="relative">
              <img
                src={candidate.image}
                className="h-[400px] w-[700px] object-cover rounded-xl bg-[var(--overlay-2)]"
                alt={candidate.name} // Adding alt attribute for better accessibility
              />
              {roleAppliedTag}
            </div>
            <div className="flex flex-col flex-grow justify-between">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <h5 className="text-3xl font-bold tracking-tight text-gray-900">
                    {candidate.name}
                  </h5>
                  {candidate.linkedin && (
                    <img
                      src={linkedin}
                      alt={candidate.name}
                      title="Linkedin icon"
                      className="h-6 w-6 ml-2"
                      onClick={() => {
                        window.open(candidate.linkedin, "_blank");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}

                  {candidate.cv && (
                    <img
                      src={cv}
                      alt={candidate.cv}
                      title="CV icon"
                      className="h-6 w-6 ml-2"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(candidate.cv, "_blank");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {candidate.github && (
                    <img
                      src={github}
                      alt={candidate.github}
                      title="Github icon"
                      className="h-6 w-6 ml-2"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(candidate.github, "_blank");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>

                <div className="flex flex-col text-right">
                  Expected Salary Range:
                  {candidate.expectedSalaryRange && (
                    <h5 className="text-md font-semibold tracking-tight text-gray-900">
                      {candidate.expectedSalaryRange}
                    </h5>
                  )}
                  {candidate.salary && (
                    <h5 className="text-md font-semibold tracking-tight text-gray-900">
                      {candidate.salary}
                    </h5>
                  )}
                </div>
              </div>
              <div className="">
                {candidate.roles &&
                  typeof candidate.roles === "string" &&
                  candidate.roles.split(",").map((role, index) => (
                    <span
                      key={index}
                      className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md md:text-md font-medium ${bgColor} ${textColor}`}
                    >
                      {role.trim()}
                    </span>
                  ))}
                {candidate.sales && (
                  <span
                    className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md md:text-md font-medium ${bgColor} ${textColor}`}
                  >
                    {candidate.sales}
                  </span>
                )}
                {candidate.swe_roles &&
                  candidate.swe_roles.map((role, index) => (
                    <span
                      key={index}
                      className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md md:text-md font-medium ${bgColor} ${textColor}`}
                    >
                      {role.trim()}
                    </span>
                  ))}
                {candidate.time_coding && (
                  <span
                    className={`inline-flex mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-md md:text-md font-medium ${bgColor} ${textColor}`}
                  >
                    {candidate.time_coding} of coding exp
                  </span>
                )}
              </div>

              <div className="flex pb-2 pt-1">
                {candidate.working_style && (
                  <p className="pr-5 font-normal flex text-md text-black ">
                    <img className="h-6 pr-1 " src={Office} />
                    {candidate.working_style}
                  </p>
                )}
                {candidate.languages !== "N/A" && candidate.languages && (
                  <p className="font-normal flex text-md text-black ">
                    <img className="h-6 pr-2" src={Languages} />
                    {candidate.languages}
                  </p>
                )}
              </div>
              <p className="text-md">
                {candidate.education &&
                  candidate.education.length > 0 &&
                  (() => {
                    const educationText = candidate.education;
                    if (educationText.includes("; ")) {
                      const splitText = educationText.split("; ")[1];
                      const [beforeAt, afterAt] = splitText.split(" @ ");
                      const [firstPart, ...restParts] = beforeAt.split("&");
                      return (
                        <>
                          <span className="font-semibold">
                            {firstPart.trim()}
                          </span>
                          {restParts.length > 0
                            ? ` &${restParts.join("&")}`
                            : ""}
                          {afterAt ? ` @ ${afterAt}` : ""}
                        </>
                      );
                    }
                    const [beforeAt, afterAt] = educationText.split(" @ ");
                    const [firstPart, ...restParts] = beforeAt.split("&");
                    return (
                      <>
                        <span className="font-semibold">
                          {firstPart.trim()}
                        </span>
                        {restParts.length > 0 ? ` &${restParts.join("&")}` : ""}
                        {afterAt ? ` @ ${afterAt}` : ""}
                      </>
                    );
                  })()}
              </p>
              <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
                {candidate.recentTarget && (
                  <div className="font-normal text-md text-gray-700 pt-2">
                    <span className="font-semibold text-black">
                      Recent Target Achieved:
                    </span>{" "}
                    {candidate.recentTarget}
                  </div>
                )}

                {candidate.work && formattedWork}
              </div>

              {candidate.description && (
                <p className="mt-2 font-normal text-md text-gray-700">
                  <span className="font-semibold text-black">
                    Most proud of:{" "}
                  </span>
                  {candidate.description}
                </p>
              )}

              {candidate.video && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(candidate.video, "_blank");
                  }}
                  className="mt-2 font-normal text-md text-blue-600 w-[220px]"
                  style={{ cursor: "pointer" }}
                >
                  <span className="font-semibold">
                    Video intro - get to know me!
                  </span>
                </a>
              )}
              {roleAppliedRational}
              {roleAppliedButtons}
            </div>
          </div>
        </div>
      </div>

      <div>
        <>
          {roleShortlistedFor ? (
            <div className="flex items-center justify-between">
              <button
                onClick={() =>
                  handleRemoveCandidate(
                    shortlistedCandidates,
                    roleShortlistedFor,
                    candidate.id
                  )
                }
                className="px-6 ml-4 py-2 mt-4 text-md border-2 text-black border-red-500 bg-white rounded-lg font-medium  hover:bg-red-500 hover:text-white"
                disabled={buttonLoading}
              >
                {buttonLoading ? (
                  <div className="flex justify-center items-center w-full rounded-lg">
                    <img
                      src={LoadingBlack}
                      className="h-6 fill-red-500"
                      alt="buttonLoading"
                    />
                  </div>
                ) : (
                  `Remove from ${roleShortlistedFor} shortlist`
                )}
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-between relative">
              <>
                <button
                  onClick={handleAddToShortlist}
                  className={`px-6 ml-4 py-2 mt-4 text-md bg-black text-black flex rounded-lg font-medium  hover:bg-[#02B491] justify-center hover:text-white border-2 border-[#02B491] bg-white`}
                >
                  {buttonLoading ? (
                    <div className="flex justify-center items-center w-full rounded-lg">
                      <img
                        src={LoadingBlack}
                        className="h-6 "
                        alt="buttonLoading"
                      />
                    </div>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5 mr-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                      </svg>
                      Add to shortlist
                    </>
                  )}
                </button>
                {showRoleMenu && (
                  <div
                    ref={roleMenuRef}
                    className="absolute ml-4 mt-44 top-300 w-48 bg-white shadow-lg rounded-lg z-10"
                  >
                    {roles.map((role, index) => (
                      <button
                        key={index}
                        onClick={() => handleRoleSelect(role, candidate.id)}
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                      >
                        {role["Role Title"]}
                      </button>
                    ))}
                    <button
                      onClick={() => newRole()}
                      className="block w-full px-4 py-2 text-left bg-[#02B491] rounded-b-lg text-sm text-white hover:bg-[#00f2c2] hover:text-black"
                    >
                      + New Role
                    </button>
                  </div>
                )}
              </>
            </div>
          )}

          {newRoleForm && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                <h2 className="text-lg font-semibold mb-4">Add New Role</h2>
                <form onSubmit={handleNewRoleSubmit}>
                  <label
                    htmlFor="title"
                    className="block  text-md font-normal leading-6 text-gray-900"
                  >
                    Role Title
                  </label>
                  <input
                    type="text"
                    value={newRoleTitle}
                    onChange={(e) => setNewRoleTitle(e.target.value)}
                    placeholder="Sales"
                    className="w-full p-2 mb-2 border border-gray-300 rounded"
                    required
                  />
                  <button
                    type="submit"
                    className="block w-full px-4 py-2 bg-[#02B491] text-sm text-white rounded hover:bg-[#00f2c2] hover:text-black"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => setNewRoleForm(false)}
                    className="block w-full px-4 py-2 mt-2 text-sm text-gray-700 hover:bg-gray-100 rounded"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default Candidate;
