import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import CandidateCard from "../../../components/candidates/generalist_card.js";
import CandidatesLoading from "../../../components/candidates/candidates_loading.js";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Shortlist() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const query = useQuery();
  const [role, setRole] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [roleNeedUpdate, setRoleNeedUpdate] = useState(true);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(true);

  const checkToken = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  const transformRoles = (roles) => {
    const roleSet = new Set(roles);
    if (roleSet.size === 1) {
      if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
      if (roleSet.has("Front End Developer")) return ["Front End"];
      if (roleSet.has("Back End Developer")) return ["Back End"];
    }
    if (
      roleSet.has("Front End Developer") &&
      roleSet.has("Back End Developer")
    ) {
      return ["Full Stack"];
    }
    if (roleSet.has("Full Stack Developer")) {
      return ["Full Stack"];
    }
    return roles;
  };

  const parseSalaryRange = (salary) => {
    const salaryRange = salary.match(/£(\d+)k?-?£?(\d+)?k?/i);
    if (salaryRange) {
      const minSalary = parseInt(salaryRange[1]) * 1000;
      const maxSalary = salaryRange[2]
        ? parseInt(salaryRange[2]) * 1000
        : minSalary;
      return { minSalary, maxSalary };
    }
    return { minSalary: null, maxSalary: null };
  };

  const fetchCandidates = useCallback(
    async (candidateIds, roleName, roleId) => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get("/api/candidates/fetchCandidates", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            ids: candidateIds.join(","),
          },
        });

        const candidates = response.data.candidates;

        const transformedData = candidates.map((candidate) => {
          console.log(candidate);
          const mostRecentCohort =
            candidate.fields?.["Most recent cohort"] || "";
          let stream = "";

          if (/^JS\d+$/.test(mostRecentCohort)) {
            stream = "Generalist";
          } else if (/^JSS\d+$/.test(mostRecentCohort)) {
            stream = "Sales";
          } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
            stream = "Software Engineering";
          } else if (/^JSM\d+$/.test(mostRecentCohort)) {
            stream = "Marketing";
          }
          let sweRoles = candidate.fields["SWE relevant roles"] || "";
          if (sweRoles) {
            sweRoles = Array.isArray(sweRoles)
              ? sweRoles[0].split(",").map((role) => role.trim())
              : sweRoles.split(",").map((role) => role.trim());
            sweRoles = transformRoles(sweRoles);
          } else {
            sweRoles = [];
          }

          let salary;
          if (stream === "Generalist" || stream === "Marketing") {
            salary = candidate.fields["Salary - cleaned"] || "";
          } else if (stream === "Sales") {
            salary = candidate.fields["Base range + commission"] || "";
          } else if (stream === "Software Engineering") {
            salary = candidate.fields["SWE Salary - cleaned"] || "";
          }
          console.log("yes");
          return {
            id: candidate["id"] || "",
            url_id: candidate.fields["ID"] || "",
            createdTime: candidate["createdTime"],
            years_of_exp:
              candidate.fields["Years of exp (or commercial exp)"] || "",
            cv: candidate.fields["Raw CV"] || "",
            video: candidate.fields["Raw video intro"] || "",
            linkedin: candidate.fields["LinkedIn raw URL"] || "",
            name: candidate.fields["Name"] || "",
            lastName: candidate.fields["Surname"],
            education: candidate.fields["Degree details"] || undefined,
            roles: candidate.fields["Generalist roles suitable for"],
            salary: salary,
            email: candidate.fields["Email"],
            image: candidate.fields.Photo
              ? candidate.fields.Photo[0].url
              : null,
            work: candidate.fields["Raw work experience"],
            working_style: candidate.fields["Preferred working style G/M/S"],
            github: candidate.fields["Github"],
            swe_roles: sweRoles,
            languages: candidate.fields["Languages"],
            time_coding: candidate.fields["Time spent coding"],
            description: candidate.fields["Top achievement"],
            mostRecentCohort: candidate.fields["Most recent cohort"],
            recentTarget: candidate.fields["Recent target achieved"],
            stream: stream,
            sales:
              candidate.fields[
                "Proven Sales Experience / High Potential for Sales"
              ],
          };
        });
        console.log(transformedData);
        console.log("ok2");
        setCandidates(transformedData);
        console.log("ok");

        const shortlistedCandidates = {};
        if (!shortlistedCandidates[roleName]) {
          shortlistedCandidates[roleName] = {
            roleId: roleId,
            candidates: [],
          };
        }
        shortlistedCandidates[roleName].candidates = transformedData;
        setShortlistedCandidates(shortlistedCandidates);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    },
    []
  );

  const fetchRole = useCallback(async () => {
    if (!roleNeedUpdate) return;
    try {
      const roleRequestId = query.get("roleRequestId");
      const response = await axios.get(
        `/api/roles/fetchRole?id=${roleRequestId}`
      );
      const role = response.data.role;
      const transformedData = {
        id: role["id"],
        url_id: role.fields["URL ID"],
        job_description_pdf: role.fields["Job Description PDF"],
        working_style: role.fields["Working Style"],
        hiring_lead_email: role.fields["Hiring Lead Email Address"],
        interview_process: role.fields["Interview Process"],
        hiring_lead_name: role.fields["Hiring Lead Name"],
        hiring_lead_linkedin: role.fields["Hiring Lead Linkedin"],
        image: role.fields["Logo (from Startup)"]
          ? role.fields["Logo (from Startup)"][0].url
          : null,
        spotlight: role.fields["Spotlight"],
        compensation: role.fields["Compensation"],
        startup_description: role.fields["Startup Description (from Startup)"],
        website: role.fields["Website (from Startup)"],
        team_size: role.fields["Team Size (from Startup)"],
        funding: role.fields["Funding Stage (from Startup)"],
        startup_name: role.fields["Name (from Startup)"][0],
        linkedin: role.fields["Startup Linkedin (from Startup)"],
        title: role.fields["Role Title"],
        key_experience: role.fields["Specific Experience"],
        additional_info: role.fields["Additional Information"],
        location: role.fields["Location (from Startup)"],
        shortlisted_candidates: role.fields["Shortlisted Candidates"],
      };
      setRole(transformedData);

      if (role.fields["Shortlisted Candidates"]) {
        const candidateIds = role.fields["Shortlisted Candidates"];
        const roleName = role.fields["Role Title"];
        const roleId = role["id"];
        await fetchCandidates(candidateIds, roleName, roleId);
      } else {
        setLoading(false);
      }

      setRoleNeedUpdate(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [roleNeedUpdate, fetchCandidates, query]);

  useEffect(() => {
    checkToken();
    fetchRole();
  }, [checkToken, fetchRole]);

  const handleRequestInterview = (roleURLId) => {
    navigate(`/request-interview2?roleRequestId=${roleURLId}`);
  };

  return (
    <div className="bg-white px-10 pl-64 pb-20">
      <button
        type="button"
        onClick={() => navigate("/shortlists")}
        className="pt-6 pl-6"
      >
        ← All shortlists
      </button>

      {loading ? (
        <div className=" pt-4">
          <div className="h-12 ml-4 bg-gray-200 rounded-full w-48 mb-4"></div>
          <CandidatesLoading />
        </div>
      ) : (
        <>
          <h2 className="pl-6 pt-4 text-4xl font-normal">{role.title}</h2>
          {candidates.length === 0 ? (
            <div className="pl-6 pt-4">
              <p>You have not shortlisted any candidates for this role yet.</p>
              <button
                type="button"
                onClick={() => navigate("/browse-candidates")}
                className="mt-4 px-4 py-2 bg-[#02B491] hover:bg-white border-2 border-[#02B491] hover:text-black text-white rounded"
              >
                Browse Candidates
              </button>
            </div>
          ) : (
            <>
              <button
                onClick={() => handleRequestInterview(role.url_id)}
                className="px-8 py-2 absolute top-10 right-20 rounded-md bg-[#02B491] text-white font-bold transition duration-200 hover:bg-white hover:text-black border-2 border-[#02B491]"
              >
                Request Interview →
              </button>
              <div className="grid grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {candidates.map((candidate, index) => (
                  <CandidateCard
                    key={index}
                    candidate={candidate}
                    role={role}
                    setRoleNeedUpdate={setRoleNeedUpdate}
                    shortlistedCandidates={role.shortlisted_candidates}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Shortlist;
