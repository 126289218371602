import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Role() {
  const query = useQuery();
  const roleId = query.get("roleId");
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/roles/fetchRole?id=${roleId}`);

        const role = response.data.role;

        console.log("fields:");
        console.log(role.fields);
        const transformedData = {
          id: role["id"] || "",
          job_description_pdf: role.fields["Job Description PDF"] ?? "",
          working_style: role.fields["Working Style"] ?? "",
          hiring_lead_email: role.fields["Surname"] ?? "",
          interview_process: role.fields["Interview Process"] ?? "",
          hiring_lead_name: role.fields["Hiring Lead Name"] ?? "",
          image:
            role.fields["Logo (from Startup)"]?.[0]?.url ??
            "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg",
          spotlight: role.fields["URL Cleaned (from Spotlight)"] ?? "",
          compensation: role.fields["Compensation"] ?? "",
          startup_description:
            role.fields["Startup Description (from Startup)"] ?? "",
          website: role.fields["Website (from Startup)"] ?? "",
          team_size: role.fields["Team Size (from Startup)"] ?? "",
          funding: role.fields["Funding Stage (from Startup)"] ?? "",
          startup_name: role.fields["Name (from Startup)"]?.[0] ?? "",
          linkedin: role.fields["Startup Linkedin (from Startup)"] ?? "",
          title: role.fields["Role Title"] ?? "",
          key_experience: role.fields["Specific Experience"] ?? "",
          additional_info: role.fields["Additional Information"] ?? "",
          location: role.fields["Location (from Startup)"] ?? "",
          dropbox_url:
            role.fields["URL Dropbox cleaned (from Spotlight)"]?.[0] ?? null,
          loom_url:
            role.fields["URL Loom cleaned (from Spotlight)"]?.[0] ?? null,
        };

        console.log("ok");

        setRole(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [roleId]);

  if (loading) {
    return (
      <div className="flex flex-col items-center bg-white pb-20">
        <div className="max-w-[700px] flex flex-col h-full mt-20 bg-white animate-pulse">
          <div className="h-[400px] w-[700px] bg-gray-300 rounded-xl"></div>
          <div className="h-6 w-64 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-32 bg-gray-300 rounded mt-2"></div>
          <div className="flex h-6 mt-2">
            <div className="h-6 w-36 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-32 mr-2 bg-gray-300 rounded"></div>
            <div className="h-6 w-20 mr-2 bg-gray-300 rounded"></div>
          </div>
          <div className="h-6 w-32 bg-gray-300 rounded mt-2"></div>
          <div className="h-40 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-32 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-60 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-32 bg-gray-300 rounded mt-2"></div>
          <div className="h-6 w-60 bg-gray-300 rounded mt-2"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!role) {
    return <p>No role data found</p>;
  }
  const profileFallback =
    "https://via.placeholder.com/600x400.png?text=No+Image+Available";

  const videoUrl =
    "https://www.dropbox.com/scl/fi/gth5nyw52k7jf4jd87ju8/32Co-JS28-Spotlight.mp4?rlkey=6jr5qox7pcoftdc2p3u6jvk9k&raw=1";

  const posterUrl =
    "https://v5.airtableusercontent.com/v3/u/29/29/1718128800000/1G1v0uweBAfNoNapHIMEXw/z2JvwdFN0Wf-kzeAIYrtuhfbdneEygRnctEsC-4uZx1R2BjNZCHscyaUJAwdpQ08G2YrdQaqmLB4JRY-eFLKI2JVNkAW1LHfKQ70VJ7C2_TnWibCUxCzXhV4hDKSGifde4bdHj54wrq-M1CpA6bA8hUXO_IJ7bcjoehehDO9kxs/OL2VanPp-TP6F3VoXrpFddV2v0dtEbYhFSoWSMTPReo";

  return (
    <div className="flex flex-col items-center bg-white pb-20">
      <div className="max-w-[700px] flex flex-col h-full mt-20 bg-white gap-2">
        <div>
          {role.dropbox_url ? (
            <video
              src={role.dropbox_url}
              className="h-[400px] w-[700px] object-cover rounded-xl bg-[var(--overlay-2)]"
              controls
              onError={(e) => {
                console.error("Failed to load video at", e.target.src);
              }}
            />
          ) : role.loom_url ? (
            <iframe
              src={role.loom_url}
              className="h-[400px] w-[700px] object-cover rounded-xl bg-[var(--overlay-2)]"
            ></iframe>
          ) : (
            <img
              src={role.image}
              className="object-cover h-[400px] w-[700px] object-cover rounded-xl"
            />
          )}
        </div>

        <div className="font-normal pt-2 text-2xl text-gray-500">
          <p className="text-black">
            <span className="text-2xl font-semibold">{role.startup_name}</span>{" "}
            - {role.title}
          </p>
        </div>
        <div className="mt-2">
          {role.compensation && (
            <span className="inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md bg-purple-100 px-4 py-1 text-sm md:text-md font-medium text-purple-700">
              Salary range: {role.compensation}
            </span>
          )}
          {role.team_size && (
            <span className="inline-flex mr-2 items-center gap-x-1.5 rounded-md bg-green-100 px-4 py-1 text-sm md:text-md font-medium text-green-700">
              {role.team_size} people in the team
            </span>
          )}

          {role.funding && (
            <span className="inline-flex mr-2 items-center gap-x-1.5 rounded-md bg-red-100 px-4 py-1 text-sm md:text-md font-medium text-red-500">
              {role.funding}
            </span>
          )}

          {role.location && (
            <span className="inline-flex mr-2 items-center gap-x-1.5 rounded-md bg-blue-100 px-4 py-1 text-sm md:text-md font-medium text-blue-500">
              {role.location}
            </span>
          )}
        </div>
        {role.startup_description && (
          <>
            <h2 className=" mt-4 font-semibold text-md text-black">
              Company Description
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.startup_description}
            </p>
          </>
        )}

        {role.website && (
          <>
            <h2 className=" mt-4 font-semibold text-md text-black">
              Company Website
            </h2>
            <a
              href={role.website}
              className="-mt-2 font-normal text-md text-blue-500 underline"
            >
              {role.website}
            </a>
          </>
        )}

        {role.job_description_pdf && (
          <>
            <h2 className=" mt-4 font-semibold text-md text-black ">
              Job Description
            </h2>
            <a
              href={role.job_description_pdf}
              className="-mt-2 font-normal text-md text-blue-500 underline "
            >
              {role.job_description_pdf}
            </a>
          </>
        )}

        {role.key_experience && (
          <>
            <h2 className=" mt-4 font-semibold text-md text-black">
              Specific Experience
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500 ">
              {role.key_experience}
            </p>
          </>
        )}

        {role.interview_process && (
          <>
            <h2 className=" mt-4 font-semibold text-md text-black">
              Interview Process
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.interview_process}
            </p>
          </>
        )}

        {role.working_style && (
          <>
            <h2 className=" mt-4 font-semibold text-md text-black">
              Team Working Style
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500 ">
              {role.working_style}
            </p>
          </>
        )}

        {role.additional_info && (
          <>
            <h2 className=" mt-4 font-semibold text-md text-black">
              Additional Info
            </h2>
            <p className="-mt-2 font-normal text-md text-gray-500">
              {role.additional_info}
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default Role;
