import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CandidateCard from "../../components/candidates/condensed_card_application.js";
import Candidate from "../../components/candidates/candidate.js";
import SuccessSVG from "../../images/success-svg.svg";
import {
  RolesLoading,
  CandidateCardLoading,
} from "../../components/loading/loading_interviews.js";

export default function Applications() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingCandidates, setLoadingCandidates] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [filter, setFilter] = useState("");
  const [roles, setRoles] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [activeRole, setActiveRole] = useState("");
  const [interviewNeedUpdate, setInterviewNeedUpdate] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showFinalPopup, setShowFinalPopup] = useState(false);
  const [candidatesRequest, setCandidatesRequest] = useState(0);
  const [isRequested, setIsRequested] = useState(true);
  const [sortOrder, setSortOrder] = useState(0);

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;

        const transformedData = roles.map((role) => {
          return {
            id: role.id,
            title: role["Role Title"],
          };
        });

        setRoles(transformedData);
        setLoadingRoles(false);
      } catch (err) {
        setError(err.message);
        setLoadingRoles(false);
      }
    };

    const transformRoles = (roles) => {
      const roleSet = new Set(roles);
      if (roleSet.size === 1) {
        if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
        if (roleSet.has("Front End Developer")) return ["Front End"];
        if (roleSet.has("Back End Developer")) return ["Back End"];
      }
      if (
        roleSet.has("Front End Developer") &&
        roleSet.has("Back End Developer")
      ) {
        return ["Full Stack"];
      }
      if (roleSet.has("Full Stack Developer")) {
        return ["Full Stack"];
      }
      return roles;
    };

    const fetchInterviews = async () => {
      try {
        const token = localStorage.getItem("token");
        const stage = "Application pending"; // New stage parameter
        const response = await axios.get("/api/interviews/fetchInterviews", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { stage: encodeURIComponent(stage) }, // Include the stage parameter in the request
        });

        const interviews = response.data.interviews;

        console.log(response);

        const transformedCandidate = interviews.map((interview) => {
          const mostRecentCohort =
            interview["Most recent cohort (from Candidate)"];
          let stream = "";

          if (/^JS\d+$/.test(mostRecentCohort)) {
            stream = "Generalist";
          } else if (/^JSS\d+$/.test(mostRecentCohort)) {
            stream = "Sales";
          } else if (/^JSWE\d+$/.test(mostRecentCohort)) {
            stream = "Software Engineering";
          } else if (/^JSM\d+$/.test(mostRecentCohort)) {
            stream = "Marketing";
          }

          let sweRoles = interview["SWE relevant roles"];
          if (sweRoles) {
            sweRoles = Array.isArray(sweRoles)
              ? sweRoles[0].split(",").map((role) => role.trim())
              : sweRoles.split(",").map((role) => role.trim());
            sweRoles = transformRoles(sweRoles);
          } else {
            sweRoles = [];
          }

          let salary;
          if (stream === "Generalist" || stream === "Marketing") {
            salary = interview["Salary - cleaned (from Candidate)"];
          } else if (stream === "Sales") {
            salary = interview["Base range + commission (from Candidate)"];
          } else if (stream === "Software Engineering") {
            salary = interview["SWE Salary - cleaned (from Candidate)"];
          }

          return {
            role: interview["Role"]?.[0] || undefined,
            ranking: interview["Ranking"] || undefined,
            role_title: interview["Role Title (from Role)"] || undefined,
            interviewId: interview["id"] || undefined,
            url_id: interview["ID (from Candidate)"] || undefined,
            stage: interview["Stage"] || undefined,
            linkedin:
              interview["LinkedIn raw URL (from Candidate)"]?.[0] || undefined,
            cv: interview["Raw CV (from Candidate)"]?.[0] || undefined,
            name: interview["Name (from Candidate)"]?.[0] || undefined,
            lastName: interview["Surname (from Candidate)"]?.[0] || undefined,
            video:
              interview["Raw video intro (from Candidate)"]?.[0] || undefined,
            education:
              interview["Degree details (from Candidate)"]?.[0] || undefined,
            roles:
              interview["Generalist roles suitable for (from Candidate)"] ||
              undefined,
            salary: salary || undefined,
            email: interview["Email (from Candidate)"]?.[0] || undefined,
            working_style:
              interview["Preferred working style G/M/S (from Candidate)"] ||
              undefined,
            image: interview["Photo (from Candidate)"]?.[0]?.url || undefined,
            work:
              interview["Raw work experience (from Candidate)"]?.[0] ||
              undefined,
            languages:
              interview["Languages & technologies (from Candidate)"] ||
              undefined,
            description:
              interview["Top achievement (from Candidate)"] || undefined,
            mostRecentCohort:
              interview["Most recent cohort (from Candidate)"]?.[0] ||
              undefined,
            recentTarget:
              interview["Recent target achieved (from Candidate)"] || undefined,
            stream: stream || undefined, // Add the stream field
            rationale: interview["Application Rationale"] || undefined,
            sales:
              interview[
                "Proven Sales Experience / High Potential for Sales (from Candidate)"
              ] || undefined,
          };
        });

        setCandidates(transformedCandidate);

        console.log(transformedCandidate);

        const requestCount = transformedCandidate.filter(
          (candidate) => candidate.stage === "Application pending"
        ).length;
        setCandidatesRequest(requestCount);

        setLoadingCandidates(false);
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "Internal Server Error" &&
          err.response.data.error ===
            "Error: No interviews found for this company"
        ) {
          setCandidates([]); // Set empty state
        } else {
          setError(err.message);
        }
        setLoadingCandidates(false);
      }
    };

    if (!interviewNeedUpdate) return;

    fetchRoles();
    fetchInterviews();
    setInterviewNeedUpdate(false);
  }, [interviewNeedUpdate]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const sortCandidates = (candidates) => {
    if (sortOrder === 1) {
      return [...candidates].sort((a, b) => {
        if (a.ranking === "Thumbs up") return -1;
        if (b.ranking === "Thumbs up") return 1;
        if (a.ranking === "Not ranked") return -1;
        if (b.ranking === "Not ranked") return 1;
        return 0;
      });
    } else if (sortOrder === 2) {
      return [...candidates].sort((a, b) => {
        if (a.ranking === "Thumbs down") return -1;
        if (b.ranking === "Thumbs down") return 1;
        if (a.ranking === "Not ranked") return -1;
        if (b.ranking === "Not ranked") return 1;
        return 0;
      });
    }
    return candidates;
  };

  const handleSortClick = () => {
    setSortOrder((prevOrder) => (prevOrder + 1) % 3);
  };

  const filteredData = candidates.filter((candidate) => {
    if (filter && candidate.stage !== filter) return false;
    if (activeRole && candidate.role !== activeRole) return false;
    return true;
  });

  const sortedData = sortCandidates(filteredData);

  return (
    <>
      <div className="relative bg-gray-100 min-h-screen">
        <div>
          <div className="flex lg:ml-[200px] h-full pb-20 justify-center items-center bg-gray-100">
            <div className="max-w-[1500px] w-full">
              <div className="bg-white pl-12 px-4 pt-6 pb-4 fixed top-18 z-20 w-full border-b">
                <div className="flex">
                  <div className="flex justify-left items-center">
                    <div>
                      <h2 className="text-3xl">Applications</h2>
                      <div className="flex">
                        <div className="mt-2 mr-8">
                          There are{" "}
                          <span className="font-bold">
                            {sortedData.length}{" "}
                          </span>{" "}
                          candidates that are waiting for a response from you.
                        </div>

                        <button
                          onClick={handleSortClick}
                          className={`flex p-2 rounded-md ${
                            sortOrder === 1 || sortOrder === 2
                              ? "bg-green-100"
                              : "hover:bg-gray-100"
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="size-6 pr-1"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                            />
                          </svg>
                          {sortOrder === 1 ? (
                            <>
                              Sorted with
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="size-6 px-1 cursor-pointer"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                                />
                              </svg>
                              at the top
                            </>
                          ) : sortOrder === 2 ? (
                            <>
                              Sorted with
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="size-6 px-1 cursor-pointer"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
                                />
                              </svg>
                              at the top
                            </>
                          ) : (
                            "Sort"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {loadingCandidates ? (
                <div className="grid pt-28 ml-20 grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  {Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <CandidateCardLoading key={index} />
                    ))}
                </div>
              ) : candidates.length === 0 ? (
                <div className="flex justify-center">
                  <div className="mt-60 bg-white max-w-md  rounded-md p-4 text-center">
                    <h2 className="text-xl">
                      You don't have any applications yet!
                    </h2>
                    <button
                      onClick={() => {
                        setShowFinalPopup(false);
                        navigate("/browse-candidates");
                      }}
                      className="px-4 mt-4 text-md py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                    >
                      Browse Candidates
                    </button>
                    <button
                      onClick={() => {
                        setShowFinalPopup(false);
                        navigate("/in-process");
                      }}
                      className="ml-2 px-4 mt-4 text-md py-2 hover:bg-[#02B491] hover:text-white rounded-md bg-white border-2 border-[#02B491] hover:text-black"
                    >
                      View in process
                    </button>
                  </div>
                </div>
              ) : (
                <div className="grid pt-28 grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  {sortedData.map((candidate, index) => (
                    <CandidateCard
                      key={index}
                      candidate={candidate}
                      onSelectCandidate={handleSelectCandidate}
                      setInterviewNeedUpdate={setInterviewNeedUpdate}
                      setShowPopup={setShowPopup}
                      setShowFinalPopup={setShowFinalPopup}
                      candidatesRequest={candidatesRequest}
                      setCandidatesRequest={setCandidatesRequest}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {showFinalPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 py-10 rounded-md shadow-md text-center">
              <h2 className="text-2xl font-bold mb-2">
                Thank you for responding to all your applications!
              </h2>
              <p className="text-md">
                Do you want to see more potential great fits for your company?
              </p>
              <div className="mt-4 space-x-2">
                <button
                  onClick={() => {
                    setShowFinalPopup(false);
                    navigate("/browse-candidates");
                  }}
                  className="px-4 py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                >
                  Browse Candidates
                </button>
                <button
                  onClick={() => {
                    setShowFinalPopup(false);
                    navigate("/in-process");
                  }}
                  className="px-4 py-2 rounded-md hover:bg-[#02B491] hover:text-white border-2 border-[#02B491]"
                >
                  View my candidates
                </button>
              </div>
            </div>
          </div>
        )}
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white w-[600px] py-10 p-6 rounded-md shadow-md text-center">
              <h2 className="text-3xl font-bold mb-2">Congrats!</h2>
              <p className="text-md">You will now be introduced over email</p>
              <div className="mt-4">
                <button
                  onClick={() => setShowPopup(false)}
                  className="px-4 py-2 bg-[#02B491] text-white rounded-md"
                >
                  Great!
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
