import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Candidates from "./pages/candidates/candidates.js";
import Navbar from "./components/navbar2.js";
import EditCompanyProfile from "./pages/edit_company_profile.js";
import EditCompanyProfile2 from "./new_pages/company_profile/edit_company_profile.js";
import Role from "./new_pages/roles/role.js";
import RedirectRole from "./new_pages/roles/redirect_role.js";

import Roles from "./pages/roles/roles.js";
import Roles2 from "./new_pages/roles/roles.js";
import CreateRole from "./new_pages/roles/create_role.js";
import Login from "./pages/login.js";
import NotFound from "./pages/not_found"; // Import the NotFound component
import Candidate2 from "./pages/candidates/candidate.js";
import Candidate from "./new_pages/candidates/candidate2.js";
import EditRole from "./new_pages/roles/edit_role.js";
import NewCandidates from "./pages/candidates/candidates2.js";
import Browse from "./new_pages/candidates/browse/browse.js";
import Interviews from "./pages/interviews/interviews.js";
import InterviewRequest from "./pages/interviews/interview_request.js";
import Shortlists from "./pages/shortlists/shortlists.js";
import Shortlists2 from "./new_pages/candidates/shortlist/shortlists.js";
import Shortlist2 from "./pages/shortlists/shortlist.js";
import Shortlist from "./new_pages/candidates/shortlist/shortlist.js";
import RequestInterview from "./pages/shortlists/request_interview.js";
import RequestInterview2 from "./new_pages/candidates/shortlist/request_interview.js";
import { ShortlistedProvider } from "./provider/ShortlistedContext.js";
import FakeLogin from "./pages/fake_login.js";
import Applications from "./new_pages/applications/applications.js";
import Requests from "./new_pages/interviews/requests.js";
import InProcess from "./new_pages/interviews/in_process.js";
import Declined from "./new_pages/interviews/declined.js";
import Onboarding from "./new_pages/onboarding/onboarding.js";
import RequestInterviewStreamlined from "./new_pages/candidates/shortlist/request_interview_streamlined.js";

function App() {
  const location = useLocation();
  const noNavbarPaths = [
    "/role",
    "/onboarding",
    "/",
    "/signin",
    "/interview-request",
    "*", // Ensure NotFound page does not show the navbar
  ]; // Add paths where you don't want the navbar

  return (
    <div className="App">
      <ShortlistedProvider>
        <div className="content bg-gray-100 h-full">
          {!noNavbarPaths.includes(location.pathname) && <Navbar />}

          <Routes>
            <Route path="/" element={<FakeLogin />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/browse-candidates" element={<Browse />} />
            <Route path="/shortlists" element={<Shortlists2 />} />
            <Route path="/shortlist" element={<Shortlist />} />
            <Route path="/request-interview" element={<RequestInterview2 />} />
            <Route
              path="/request-interview-streamlined"
              element={<RequestInterviewStreamlined />}
            />
            <Route path="/role" element={<Role />} />
            <Route path="/role/:id" element={<RedirectRole />} />
            <Route path="/company-profile" element={<EditCompanyProfile2 />} />
            <Route path="/new-role" element={<CreateRole />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/in-process" element={<InProcess />} />
            <Route path="/declined" element={<Declined />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/interview-request" element={<InterviewRequest />} />
            <Route path="/roles" element={<Roles2 />} />
            <Route path="/candidate/:id" element={<Candidate />} />
            <Route path="/edit-role" element={<EditRole />} />
            <Route path="/request-interview2/" element={<RequestInterview />} />
            <Route path="/candidate2/:id" element={<Candidate />} />
            <Route path="/roles2" element={<Roles />} />
            <Route path="/role" element={<Role />} />
          </Routes>
        </div>
      </ShortlistedProvider>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
