import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditRole from "../../components/edit_role";
import DeleteIcon from "../../images/delete-icon.png"; // Importing the delete icon
import DeleteIconRed from "../../images/delete-icon-red.png"; // Importing the delete icon
import EditIcon from "../../images/edit-icon.png"; // Importing the edit icon
import EditIconGreen from "../../images/edit-icon-green.png"; // Importing the green edit icon

function Roles() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [spotlights, setSpotlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [hoveredRoleId, setHoveredRoleId] = useState(null);
  const [hoveredEditIconId, setHoveredEditIconId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;
        const transformedData = roles.map((role) => {
          const createdDate = new Date(role["Role creation data"]);
          const createdMonthYear = createdDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
          return {
            id: role["id"],
            createdTime: role["Role creation data"],
            createdMonthYear: createdMonthYear,
            equity: role["Equity"],
            role_title: role["Role Title"],
            hiring_lead_name: role["Hiring Lead Name"],
            hiring_lead_email: role["Hiring Lead Email Address"],
            hiring_lead_linkedin: role["Hiring Lead Linkedin"],
            key_experience: role["Specific Experience"],
            job_description_link: role["Job Description PDF"],
            startup: role["Startup"],
            compensation: role["Compensation"],
            interviewProcess: role["Interview Process"],
            working_style: role["Working Style"],
            spotlight: role["Spotlight"],
            url_id: role["URL ID"],
            status:
              role["Open to applications"] === "Open to apps" ? true : false,
          };
        });

        setData(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchSpotlights = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/spotlights/fetchSpotlights", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const spotlights = response.data.spotlights;
        const transformedData = spotlights.map((spotlight) => ({
          id: spotlight["id"],
          url: spotlight["URL"],
          name: spotlight["Name"],
          comment: spotlight["Comment"],
        }));

        console.log(transformedData);

        setSpotlights(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
    fetchSpotlights();
  }, []);

  const handleDelete = async (roleId) => {
    try {
      const updatedData = {
        fields: { Deleted: true },
        roleId: roleId,
      };
      const token = localStorage.getItem("token");
      await axios.patch(`/api/roles/updateRoleData`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData((prevData) => prevData.filter((role) => role.id !== roleId));
      setSelectedRole(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const toggleStatus = async (itemId, currentStatus) => {
    try {
      // Determine the new status string based on the current boolean value
      const newStatus = currentStatus ? "Not open to apps" : "Open to apps";

      const updatedData = {
        fields: { "Open to applications": newStatus },
        roleId: itemId,
      };

      const token = localStorage.getItem("token");
      await axios.patch(`/api/roles/updateRoleData`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the local state with the new status
      setData((prevData) =>
        prevData.map((item) =>
          item.id === itemId
            ? { ...item, status: newStatus === "Open to apps" }
            : item
        )
      );
    } catch (err) {
      setError(err.message);
    }
  };

  const renderLoadingState = () => {
    return (
      <div className="relative max-w-[1400px] overflow-x-auto border-2 sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-3">
                Role Title
              </th>
              <th scope="col" className="px-4 py-3">
                Salary Compensation
              </th>
              <th scope="col" className="px-4 py-3">
                Job Description
              </th>
              <th scope="col" className="px-4 py-3">
                Hiring Manager Name
              </th>
              <th scope="col" className="px-4 py-3">
                Hiring Manager Email
              </th>
              <th scope="col" className="px-4 py-3">
                Created Month
              </th>
              <th scope="col" className="px-4 py-3"></th>
              <th scope="col" className="px-4 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {[...Array(3)].map((_, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-32"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-28"></div>
                </td>
                <td className="px-4 py-4 font-medium text-blue-600 hover:underline">
                  Job Description
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-32"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-40"></div>
                </td>
                <td className="px-4 py-4">
                  <div className="h-4 bg-gray-200 rounded-full  w-28"></div>
                </td>
                <td className="px-4 py-4">
                  <img
                    className="inline-block h-6 cursor-pointer"
                    src={EditIcon}
                  />
                </td>
                <td className="px-4 py-4">
                  <img
                    className="inline-block h-6 cursor-pointer"
                    src={DeleteIcon}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center bg-white pt-28 pl-56 pb-2 px-4">
      <div>
        {loading ? (
          renderLoadingState()
        ) : (
          <div className="relative max-w-[1400px] overflow-x-auto border-2 sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Role Title
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Salary Compensation
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Job Description
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Hiring Manager Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Hiring Manager Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Created Month
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-8 py-3"></th>
                  <th scope="col" className="px-8 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b hover:bg-gray-50 "
                  >
                    <th
                      scope="row"
                      className="px-4 py-4 font-medium text-gray-900  "
                    >
                      {item.role_title}
                    </th>
                    <td className="px-4 py-4">{item.compensation}</td>
                    <td className="px-4 py-4 whitespace-normal break-words">
                      {item.job_description_link ? (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(item.job_description_link, "_blank");
                          }}
                          className="font-medium text-blue-600 cursor-pointer hover:underline"
                        >
                          Job Description
                        </a>
                      ) : (
                        <>Missing Job Description</>
                      )}
                    </td>

                    <td className="px-4 py-4">{item.hiring_lead_name}</td>
                    <td className="px-4 py-4">{item.hiring_lead_email}</td>
                    <td className="px-4 py-4">{item.createdMonthYear}</td>
                    <td className="px-4 py-4">
                      {item.status ? (
                        <button
                          onClick={() => toggleStatus(item.id, item.status)}
                          className="p-2 text-sm whitespace-nowrap justify-center hover:border-green-600 border border-white items-center flex rounded-md bg-green-100"
                          data-tip="Disable candidates to apply directly to this role"
                        >
                          <span className="size-1.5 inline-block rounded-full bg-green-600 mr-2"></span>
                          Receiving applications
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={() => toggleStatus(item.id, item.status)}
                          className="p-2 text-sm whitespace-nowrap justify-center hover:border-orange-600 border border-white items-center flex rounded-md bg-orange-100"
                          data-tip="Allow candidates to apply directly to this role"
                        >
                          <span className="size-1.5 inline-block rounded-full bg-orange-600 mr-2"></span>
                          Not receiving applications
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>
                        </button>
                      )}
                    </td>

                    <td className="px-4 py-4">
                      <img
                        className="inline-block h-6 cursor-pointer"
                        src={
                          hoveredEditIconId === item.id
                            ? EditIconGreen
                            : EditIcon
                        }
                        onClick={() =>
                          navigate(`/edit-role?roleId=${item.url_id}`)
                        }
                        onMouseEnter={() => setHoveredEditIconId(item.id)}
                        onMouseLeave={() => setHoveredEditIconId(null)}
                      />
                    </td>
                    <td className="px-4 py-4">
                      <img
                        className="inline-block h-6 cursor-pointer"
                        src={
                          hoveredRoleId === item.id ? DeleteIconRed : DeleteIcon
                        }
                        onClick={() => setSelectedRole(item.id)}
                        onMouseEnter={() => setHoveredRoleId(item.id)}
                        onMouseLeave={() => setHoveredRoleId(null)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="w-full max-w-[1400px] flex justify-end mt-5">
          <button
            type="submit"
            className="px-6 flex py-2 mb-4 text-md border-2 border-[#02B491] text-white hover:text-black rounded-lg font-medium transform hover:bg-white bg-[#02B491]"
            onClick={(e) => navigate("/new-role")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            Create a new role
          </button>
        </div>
      </div>

      {selectedRole && (
        <>
          {/* Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

          {/* Modal */}
          <div
            id="popup-modal"
            className="fixed inset-0 flex justify-center items-center z-50"
          >
            <div className="relative p-4 w-full max-w-md">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  type="button"
                  onClick={() => setSelectedRole(null)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-4 md:p-5 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>

                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want to delete this role?
                  </h3>
                  <button
                    onClick={() => handleDelete(selectedRole)}
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    onClick={() => setSelectedRole(null)}
                    type="button"
                    className="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Roles;
