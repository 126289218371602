import React, { useEffect, useState } from "react";

function SmallCard({ savedCandidate, onToggleCandidate }) {
  const getColorClasses = (stream) => {
    switch (stream) {
      case "Generalist":
        return {
          bgColor: "bg-emerald-100",
          textColor: "text-emerald-700",
          workBgColor: "bg-emerald-50",
          fillColor: "fill-emerald-700",
          bgColor2: "bg-emerald-400",
        };
      case "Sales":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
          bgColor2: "bg-blue-400",
        };
      case "Software Engineering":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
          bgColor2: "bg-orange-400",
        };
      case "Marketing":
        return {
          bgColor: "bg-purple-100",
          textColor: "text-purple-700",
          workBgColor: "bg-purple-50",
          fillColor: "fill-purple-700",
          bgColor2: "bg-purple-400",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
          bgColor2: "bg-gray-400",
        };
    }
  };

  const { bgColor, textColor, workBgColor, fillColor, bgColor2 } =
    getColorClasses(savedCandidate.stream);
  return (
    <div className="max-h-[110px] max-w-sm mx-auto mt-2 pt-2 pl-15 bg-white rounded-lg overflow-hidden flex w-full">
      <a href="#">
        <img
          className="w-16 h-16 mb-2 object-cover rounded-lg"
          src={savedCandidate.image}
          alt="Descriptive alt text"
        />
      </a>
      <div className="pt-2 pl-2 flex flex-col flex-grow">
        <a href="#">
          <div className="flex items-center">
            <h5 className="text-lg font-semibold tracking-tight text-gray-900">
              {savedCandidate.name}
            </h5>
          </div>
        </a>
        <div className="">
          <span
            className={`inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
          >
            <svg
              className={`h-1.5 w-1.5 ${fillColor}`}
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            {savedCandidate.stream}
          </span>
        </div>
      </div>
    </div>
  );
}

export default SmallCard;
